import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

// import { motion, useTransform, useViewportScroll } from "framer-motion";

import styles from './ScrollMarquee.module.scss';
import { css } from '@oddcommon/utils';

const MOBILE_BREAKPOINT = 900;
const MODIFIER_SCALE_FACTOR = 1.15;

export default function ScrollMarquee({ items, direction = 'left', speed, hover }) {
  const innerRef = useRef();
  const [speedModifier, setSpeedModifier] = useState();

  const [ref, inView] = useInView({
    threshold: 0,
  });
  const [progress, setProgress] = useState(0);

  const isRight = direction === 'right';

  useEffect(() => {
    const element = innerRef.current;
    const handleScroll = () => {
      // Only calculate scroll if the element is in view
      if (inView && element) {
        const inner = element.getBoundingClientRect();
        const winHeight = window.innerHeight;
        const winWidth = window.innerWidth;
        const newSpeedModifier =
          winWidth < MOBILE_BREAKPOINT ? (MOBILE_BREAKPOINT / winWidth) * MODIFIER_SCALE_FACTOR : 1;
        setSpeedModifier(newSpeedModifier);
        // Progress from when the top of the element enters to when the bottom leaves
        let newProgress = 1 - (inner.top + inner.height) / (winHeight + inner.height);
        newProgress = newProgress > 1 ? 1 : newProgress; // Max value 1
        newProgress = newProgress < 0 ? 0 : newProgress; // Min value 0
        setProgress(newProgress);
      }
    };
    handleScroll();
    window.addEventListener('resize', handleScroll);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', handleScroll);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [inView, innerRef, progress]);

  const orderedItems = isRight
    ? // When scrolling right, the first item displays first, and the other items precede it in
      // reverse order (from the preceding 'copy' of the sequence). This means as it scrolls, to the
      // right, the items appear in the listed order.
      [items[0], ...items.slice(1).reverse()]
    : items;

  return (
    <div className={css(styles.base, hover && styles.hover)} ref={ref}>
      <div
        className={styles.inner}
        ref={innerRef}
        style={{
          transform: `translateX(${
            (isRight ? -1 : 1) * speed * speedModifier * progress
          }%) translateZ(0)`,
        }}
      >
        {/* Create three copies of the text */}
        {['pre', 'main', 'post'].map(whichCopy => (
          <div key={whichCopy} className={styles[whichCopy]}>
            {orderedItems.map((item, itemIndex) => (
              <div className={styles.item} key={itemIndex}>
                {item}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

ScrollMarquee.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  speed: PropTypes.number,
};
ScrollMarquee.defaultProps = {
  speed: 100,
};
