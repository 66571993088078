import React, { useState, useContext, useEffect, useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { GlobalContext } from '@context';
import { useInView } from 'react-intersection-observer';

const CaseStudyThumb = ({ styles, content, thumbnailImage, alt, loaded }) => {
  const { lowBattery, actions } = useContext(GlobalContext);
  const videoRef = useRef();

  // Videos only become visible after they start playing
  // This solves issues with low power mode on mobile
  const [hasPlayed, setHasPlayed] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      videoRef.current.play().catch(() => {
        actions.setLowBattery(true);
      });
    }
  }, [inView]);

  const thumbContent =
    content.file.contentType === 'video/mp4' ? (
      <div style={{ position: 'relative', height: '100%', width: '100%' }} ref={ref}>
        <GatsbyImage
          style={{
            pointerEvents: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: hasPlayed ? 0 : 1,
          }}
          className={styles.image}
          placeholder="blurred"
          image={getImage(thumbnailImage)}
          alt={alt}
          onLoad={() => {
            if (loaded) loaded();
          }}
        />
        <video
          ref={videoRef}
          className={styles.video}
          playsInline
          muted
          loop
          style={{
            pointerEvents: 'none',
            opacity: hasPlayed ? 1 : 0,
            visibility: hasPlayed ? 'visible' : 'hidden',
          }}
          onLoadedMetadata={e => {
            if (loaded) loaded();
          }}
          onCanPlay={e => {
            if (loaded) loaded();
          }}
          onPlay={() => {
            setHasPlayed(true);
          }}
        >
          <source type={content.file.contentType} src={content.file.url} />
        </video>
      </div>
    ) : (
      <GatsbyImage
        style={{ pointerEvents: 'none' }}
        className={styles.image}
        placeholder="blurred"
        image={getImage(content)}
        alt={alt}
        onLoad={() => {
          if (loaded) loaded();
        }}
      />
    );
  return thumbContent;
};

export default CaseStudyThumb;
