import React, { useState } from 'react';
import GatsbyImage from 'gatsby-image';
import { getImage } from 'gatsby-plugin-image';

const CLIENT_JOIN = ' + ';

// If there are multiple clients combine their names into one string
export const formatClients = clients => {
  if (!clients || clients.length === 0) {
    return;
  }
  let clientName = '';
  clients.forEach((clientObj, index) => {
    if (!clientObj.name) {
      return;
    }
    if (index) {
      clientName += CLIENT_JOIN;
    }
    clientName += clientObj.name;
  });
  return clientName;
};
